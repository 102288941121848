.offer_details {
  width: 50%;
  margin: 50px auto;

  h1 {
    font-size: 1.5rem;
    color: $primary-color-dark;
  }

  &-account {
    padding: 30px 40px;
    background-color: #fff;
    margin-top: 30px;
    p {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0;
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 600;
      color: $primary-color-light;
    }

    &-info {
      p {
        margin: 0;
      }

      span {
        font-size: 0.875rem;
      }
    }
  }

  &-line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $icon-background;
    margin: 18px 0;
  }
  
  &-info {
    padding: 30px 40px;
    background-color: #fff;
    margin-top: 30px;

    h4 {
      font-size: 1.125rem;
      font-weight: 600;
      color: $primary-color;
      margin-bottom: 20px;
    }

    p {
      font-size: 0.875rem;
      margin: 10px 0;
    }

    &-value {
      font-weight: 600;
    }

    .offer_details-line {
      margin: 10px 0;
    }
  }

  &-time {
    padding: 40px;
    background-color: #fff;
    margin-top: 30px;

    p {
      margin: 0;
    }

    .offer_details-line {
      margin: 20px 0;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      width: 48%;
      font-weight: 600;

      &:first-child {
        background-color: $primary-color;
        color: #fff;
      }

      &:last-child {
        background-color: transparent;
        color: $primary-color;
        border: 1px solid $primary-color
      }
    }
  }

  @include responsive_tablet {
    width: 100%;

    &_container {
      width: 90% !important;
    }

    &-account {
      padding: 20px 30px;
      p {
        width: 10%;
      }
    }
    &-info {
      padding: 20px 30px;
      
      h4 {
        text-align: center;
      }
    }
    
    &-time {
      padding: 20px;

      &-date {
        width: 60%;
      }
    }

    &-buttons {
      flex-direction: column;

      button {
        width: 100%;
        margin: 10px;
      }
    }
  }
}

/*--------MODALS--------*/

.offer_modal_confirmation {
  h2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &_text {
    border-radius: 8px;
    border: 1px dashed $icon-background;
    padding: 15px 40px;
    p {
      margin: 0;
    }

    span {
      font-size: 1.5rem;
      color: $primary-color-light;
      margin-left: 20px;
    }

    &-code {
      font-weight: 600;
    }

    @include responsive_tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.offer_modal_negociate {
  h5 {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  p {
    font-size: 0.875rem;
    margin: 6px 0;
  }

  input {
    border: 0;
    outline: 0;
    text-align: center;
    font-size: 2.125rem;
    color: $primary-color-light;
    font-weight: 600;
    margin: 10px 0 20px 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type=number] {
    appearance:textfield;
  }

  .offer_details-line {
    margin: 10px 0;
  }

  &-item {
    p {
     &:last-child {
      font-weight: 600;
     } 
    }

    &-total {
      color: $primary-color-light;
      font-size: 1.125rem !important;
    }
  }

  button {
    margin-top: 30px;
  }

}