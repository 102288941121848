.alerts {
  &_title {
    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.875rem;
    }
  }

  &_exchange {
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 30px;

    &_rate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 0.75rem;
        margin: 0;
        color: $primary-color-dark;
        font-weight: 400;
      }
      span {
        font-size: 1.25rem;
        margin-left: 15px;
        font-weight: 600;
        color: $primary-color;
      }

      &_draw {
        background-color: $primary-color-light;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          transform: rotate(90deg);
        }
      }
    }

  }

  &_item {
    background: #fff;
    position: relative;
    padding: 30px 30px 20px 30px;
    border-radius: 10px;

    &_type {
      position: absolute;
      top: -15px;
      left: 30px;
      background-color: $primary-color;
      padding: 3px 30px;
      color: #fff;
      border-radius: 8px;
      span {
        font-weight: 700;
        font-size: 0.725rem;
        letter-spacing: 1.5pt;
      }
    }

    .alert_compra {
      background-color: $primary-color;
    }
    
    .alert_venta {
      background-color: $secondary-color;
    }

    &_info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_text {
        display: flex;

        p {
          font-size: 0.625rem;
          font-weight: 700;
          color: $primary-color;
          margin: 0;
        }
        span {
          font-size: 1.25rem;
          font-weight: 600;
          color: $primary-color-light;
          margin: 0;
          margin-left: 15px;
        }
      }

      img {
        cursor: pointer;
      }
    }

    &_line {
      width: 100%;
      margin: 15px 0;
      border: 1px dashed $icon-background;
    }

    &_delete {
      display: flex;
      align-items: center;
      p {
        text-decoration: underline;
        margin: 0;
        margin-left: 5px;
        color: $primary-color;
        font-size: 0.75rem;
        cursor: pointer;
      }
      img {
         cursor: pointer;
      }
    }

  }
  &_button {
    background-color: $primary-color-light;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    p {
      margin: 0;
      font-size: 0.875rem;
    }
  }

  &_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 60px 0 70px;
    img {
      width: 70px;
      height: 70px;
    }

    p {
      font-size: 0.875rem;
      color: $primary-color-dark;
      font-weight: 700;
      text-align: center;
    }

    &_button {
      display: flex;
      justify-content: center;
      button {
        width: 270px;
        background-color: $primary-color-light;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        p {
          margin: 0;
          color: #fff;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }
  }

  &_create {
    width: 40%;
    margin: 0 auto;
    h1 {
      font-size: 1.7rem;
      text-align: center;
      margin-bottom: 30px;
    }
    
    &_form {
      background-color: #fff;
      padding: 40px;
      .input {
        margin-top: 20px;
      }

      span {
        font-size: 0.625rem;
        color: $primary-color;
        font-weight: 700;
      }

      p {
        font-size: 0.875rem;
      }

      &-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        button {
          width: 48%;

          &:first-child {
            background-color: $primary-color-light;
            color: #fff;
          }

          &:last-child {
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
      }
    }
  }

  @include responsive_tablet {

    &_title {
      text-align: center;
    }
    
    &_exchange {
      padding: 20px 0;
      &_rate {
        flex-direction: column;
        text-align: center;
  
        span {
          margin: 0;
        }
      }
    }

    &_button {
      margin: 10px 0 50px 0;
    }

    &_create {
      width: 100%;

      &_form {
        padding: 30px;

        &-buttons {
          flex-direction: column;

          button {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

/*--------MODALS-------*/

.alerts_modal {
  text-align: center;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  button {
    width: 60%;
    margin: 0 auto;
  }
}