@mixin responsive_phone {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin responsive_tablet {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin responsive_large_tablet {
    @media screen and (max-width: 1100px) {
        @content;
    }
}


@mixin responsive_desktop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
