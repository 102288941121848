.carousel-indicators {
  bottom: -50px !important;

  button {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    margin: 0 13px !important;
  }

  @media screen and (max-width: 1600px) {
    bottom: -25px !important;
  }
}

.carousel-control {
  display: none !important;
}