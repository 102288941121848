.exchange {
  min-height: 100vh;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 50px;
  }
  
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .details-quote {
    height: 600px;

    .container {
      height: 100%;
    }
  }
  
  &_type {
    margin-top: 40px;
    padding: 40px;
    background: $white-color;
    height: 473px;


    &.details-quote {
      height: 530px;
    }
    &.scheduled {
      height: 580px;

      &.errorQuote {
        height: 610px;
      }
    }

    &.errorQuote {
      height: 500px;
    }

    h3 {
      text-align: center;
      color: $primary-color-dark;
    }

    &_line {
      width: 1px !important;
      height: 20px;
      border-right: 1px solid #D4E1FD;
      display: flex;
      justify-content: flex-start;
      padding: 0 !important;
    }

    &.interbank_type {
      height: 370px;
      & input[type="text"] {
        text-align: center;
        font-size: 1.75rem;
        color: $primary-color-light;
        font-weight: 500;
        padding: 10px 0;

        &::placeholder {
          color: $primary-color-light;
        }
      }

      &.max-height {
        height: 420px;
      }
    }

  }

  &_calculate {
    margin-top: 30px !important;
    input, select {
      font-size: 1.25rem;
      height: 100%;
    }
  }

  &_line {
    margin: 30px 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed $icon-background;
  }

  &_coupon {
    p, input {
      font-size: 0.875rem;
    }

    button {
      width: 100%;
      height: 100%;
      background: $very-light-color;
      color: $primary-color-light;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &_init {
    button {
      width: 100%;
      margin-top: 20px;

      &:disabled {
        background-color: rgba(242, 242, 242, 1);
        color: rgba(178, 178, 178, 1);
      }
    }

    &.interbank_init {
      button {
        background-color: $primary-color;
        color: white;
        font-weight: 600;
        &:disabled {
          background-color: rgba(242, 242, 242, 1);
          color: rgba(178, 178, 178, 1);
          cursor: not-allowed;
        }
      }

      & .cancel_interbank {
        background: none;
        color: $primary_color;
      }
    }
  }

  &_schedule {
    h3 {
      width: 100%;
      text-align: left;
      margin-right: auto;
    }
  }

  &_details {
    background-color: $icon-background;
    

    .no-operation {
      margin-top: 250px;
    }

    &.open-details {
      background-color: transparent !important;
    }
    p {
      font-size: 0.875rem;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .open-details.container {
      margin-top: 120px;
    }

    &_rate {
      display: flex;
      align-items: center;
      background-color: #fff;
      width: 100%;
      padding: 30px;
      margin-bottom: 30px;

      p {
        margin: 0;
      }

      span {
        color: $primary-color-light;
        font-size: 1.5rem;
        font-weight: 600;
        margin-left: 20px;
      }

      input {
        border: 0;
        background: transparent;
        outline: 0;
        margin-left: 20px;
        width: 80px;
        color: $primary-color-light;
        font-size: 1.5rem;
        font-weight: 600;
        border-bottom: 1px solid black;
        
        &::placeholder {
          color: $primary-color-light;
          font-weight: 600;
        }
      }
    }

    &_info {
      width: 100%;
      background-color: $white-color;
      padding: 45px 40px;
      background: $white-color;
      margin: auto 0;
      h3 {
        text-align: left;
        color: $primary-color-dark;
      }

      p {
        margin: 5px 0;
      }

      p, span {
        font-size: 0.875rem;
      }

      span {
        font-weight: 600;
      }

      &_line {
        margin: 10px 0;
        height: 1px;
        width: 100%;
        border-bottom: 1px dashed $icon-background;
      }

      &_result {
        p {
          font-weight: 600;
        }
        span {
          font-size: 1.125rem;
          color: $primary-color-light;
          font-weight: 600;
        }

      }

      &_savings {
        width: 100%;
        height: 45px;
        padding: 5px;
        border-radius: 8px;
        border: 1px dashed $icon-background;
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }

    &.interbank_details {
      .exchange_details_info {
        padding: 45px 40px;

        h3 {
          color: $primary-color;
        }

        p, span {
          color: $primary-color-dark;
        }
      }

      .no-operation {
        margin-top: 200px;
      }

      .exchange_details_info_result {
        p {
          font-weight: 600;
        }
        span {
          font-size: 1.125rem;
          color: $primary-color-light;
          font-weight: 600;
        }

      }
    }
  }

  @include responsive_tablet {
    width: 100%;
    h2 {
      text-align: center;
    }

    .container {
      width: 100% !important;
      margin: 0 auto;
    }

    &_details {
      background-color: transparent;
      .container {
        margin: 50px auto !important;
      }
    }

    &_type {
      padding: 30px 20px;
      margin: 30px auto;

      .form-check {
        label {
          font-size: 0.9rem;
        }
      }
    }
    
  }

}

.interbank_modal_confirmation {
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 500;
    color: $primary-color-light;
    text-align: center;
    margin: 20px 0;
  }

  p, span {
    font-size: 0.875rem;
    color: $primary-color-dark;
  }

  span {
    font-weight: 600;
  }

  &_line {
    margin: 15px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $icon-background;
  }

  &_text {
    margin-top: 15px;
    padding: 20px;
    border-radius: 10px;
    border: 1px dashed $primary-color-light;

    p {
      margin: 0;
    }
  }
}

.stepper_module {
  margin-top: 3%;
  h2 {
    text-align: center;
    font-size: 1.5rem;
  }
  .stepper_container {
    margin-top: 30px;
    .stepper {
      display: flex;
      flex-direction: column;
      align-items: center;

      &_info, &_rate {
        padding: 20px;
        border-radius: 8px;
        p {
          margin: 0;
          color: $primary-color-dark;
        }
      }
      &_info {
        background-color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;

        p {
          font-size: 0.875rem;
        }

        &_exchange {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_amount {
          font-weight: 600;
          color: $primary-color-light !important;
        }
      }

      &_rate {
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        p {
          font-size: 0.875rem;
        }

        &_final_exchange {
          font-weight: 700;
        }
      }
      .stepper-header {
        display: flex;
        justify-content: center;
        width: 60%;
        & ol {
          z-index: 2;

          & li.ng-star-inserted {
            padding-bottom: 5px;
            .step-bullet {
              background-color: white;
              border: 1px solid rgba(145, 164, 243, .5);
              width: 45px;
              height: 45px;
              p {
                color: rgba(145, 164, 243, .5);
                font-weight: 600;
                margin: 0;
                font-size: 1.2rem;
              }
            }

            .step-title {
              color: $primary-color-light;
              opacity: .5;
              font-weight: 600;

              font-size: 0.825rem;
            }

            &:after {
              background-color: rgba(212, 225, 253, 1) !important;
            }
            &:nth-child(2) {
              &:after {
                background: $primary-color-light !important;
              }
            }
          }


          & li {
            padding: 15px 0;
          }

          & li.ng-star-inserted.active {
            .step-bullet {
              p {
                color: white;
              }
              background-color: $primary-color-light !important;
              box-shadow:0px 0px 19px rgba(131, 170, 255, 1);
            }
            .step-title {
              color: #000;
              opacity: 1;
              
            }

            &:after{
              background-color: rgba(64, 192, 43, 1) !important;
            }
          }

          & li.ng-star-inserted.completed {
            .step-bullet {
              p {
                color: white;
              }
              background-color: rgba(64, 192, 43, 1) !important;

              p {
                display: none;
              }
              &::after {
                content: none;
                content: "\2713";
              }
            }
            .step-title {
              color: #000;
              opacity: 1;
            }

            &:after{
              background-color: rgba(64, 192, 43, 1) !important;
            }
          }
        
        }
        
      }
    
      .stepper-body {
        padding: 0;
        margin: 0;
        width: 80%;
        margin-top: 50px;
      }

      &_accounts {
        p, h4 {
          font-size: 0.895rem;
          text-align: center;
          color: $primary-color-dark;
        }
        h4 {
          font-weight: 600;
        }
        padding: 40px;
        border: 1px dashed $icon-background;
        border-radius: 10px;

        &_list {
          margin: 0 auto;

          &_item {
            background: white;
            padding: 20px 10px;
            border-radius: 8px;
            margin: 10px 0;
            cursor: pointer;

            &.account_selected {
              border: 1px solid $primary-color-light;
              filter: drop-shadow(0px 0px 5px rgba(155, 175, 241, 0.4));
            }
            &.locked_account {
              opacity: 0.5;
              cursor: not-allowed;
            }

            &_opened {
              &_line {
                width: 100%;
                height: 1px;
                border-bottom: 1px dashed $icon-background;
                margin: 15px 0;
              }
            }
            &_info {
              p {
                text-align: left;
                margin: 0 0 0 16px;
                color: $primary-color-dark;

                &:first-child {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      &_buttons {
        display: flex;
        justify-content: center;
        button {
          width: 20%;
          margin: 30px 20px;
          &:nth-of-type(1) {
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
            font-weight: 600;
          }
          &:nth-of-type(2) {
            background-color: $primary-color;
            font-weight: 600;
          }
        }
      }

      &_result {
        background-color: white;
        padding: 30px;
        margin-bottom: 30px;
        border-radius: 10px;

        &_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin: 0;
            font-size: 0.875rem;
            &:nth-child(1) {
              font-weight: 600;
              color: $primary-color;
            }

            &:nth-child(2) {
              font-weight: 600;
              color: $primary-color-light;
            }
          }
        }

        &_line {
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed $icon-background;
          margin: 20px 0;
        }

        &_accounts {
          &_item {
            p {
              font-size: 0.875rem;
              &:nth-child(1){
                font-weight: 600;
              }
            }

            &_amount {
              font-weight: 600;              
            }
          }
        }
        &_button {
          background: transparent;
          border: 1px solid $primary-color;
          color: $primary-color;
          width: 200px;
          font-weight: 600;
          font-size: 0.875rem;
        }

        &_button_responsive {
          display: none;
        }

        &_details {
          background-color: white;
          padding: 40px;
          border-radius: 10px;

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: $primary-color;
            margin: 0 0 20px 0;
          }


          &_line {
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed $icon-background;
            margin: 15px 0;
          }

          &_row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              color: $primary-color-dark;
              font-size: 0.875rem;
              margin: 4px 0;
              &:nth-child(2) {
                font-weight: 600;
              }
            }

            &:last-of-type {
              p {
                font-weight: 600;
                &:nth-child(2) {
                  color: $primary-color-light;
                  font-size: 1.125rem;
                }
              }
            }
          }

          button {
            margin: 30px 0 0 0;
          }
        }
      }

  
    }
  }

  @include responsive_large_tablet {
    width: 100% !important;

    .stepper_container {
      .stepper {
        .stepper-header {
          width: 100%;
        }

        .stepper-body {
          width: 100%;
        }

        .steps {
          padding: 0 !important;
        }

        &_info, &_rate {
          padding: 10px 5px;
          margin: 5px 0;
        }

        &_rate {
          display: flex;
          justify-content: center;
          padding: 5px;
          p {
            &:nth-child(2) {
              margin: 0 10px;
            }
          }
        }

        &_buttons {
          flex-direction: column;

          button {
            width: 100%;
            margin: 5px auto;

            &:first-child {
              margin-top: 30px;
            }
          }
        }

        &_result {
          &_button {
            display: none;
          }

          &_details {
            padding: 30px;
          }

          &_button_responsive {
            display: block;
            background: transparent;
            border: 1px solid $primary-color;
            color: $primary-color;
            width: 90%;
            font-weight: 600;
            font-size: 0.875rem;
            margin: 30px auto;
          }
        }
      }
    }
  }
}

.operation_details {
  margin-top: 5%;
  h2 {
    font-size: 1.5rem !important;
    margin-bottom: 50px;
  }

  h3, p {
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  h3 {
    font-weight: 600;
  }
  
  &_buttons {
    margin-top: 50px;
    button {
      width: 250px;
      margin: 20px 0;
      font-size: 0.775rem !important;
      &:nth-child(1) {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent;
        font-weight: 600;
      }
      &:nth-child(2) {
        background-color: $primary-color;
        color: $white-color;
      }
    }
  }

  &_timeline {
    .timeline {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
  
      p {
        font-size: 15px;
      }
    }

    .timeline-date {
      display: flex;
      flex-direction: column;

      small {
        font-size: .65rem;
        line-height: 18px;
      }

      a {
        font-size: .75rem;
      }
      
    }
  
    .timeline-event {
        display: flex;
        position: relative;
        min-height: 100px;
    }
  
    .heighlight {
        background-color: $steps-color;
    }
  
    .timeline-step-title {
        color: $primary-color-dark;
        p {
          font-size: 0.7225rem;
          font-weight: bold;
          margin: 5px 0 0 0 !important;
        }
  
        .title-grey {
          color: rgba($primary-color-dark, .2);
        }
    }
  
    .timeline-event:last-child {
        min-height: 0;
    }
  
    .timeline-content {
        flex: 1;
        padding: 0 1rem;
    }
  
    .timeline-point-track-container {
        flex: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
  
    .timeline-point {
        display: flex;
        align-self: baseline;
        border-radius: 50%;
        width: 2.3rem;
        height: 2.3rem;
        background-color: white;
        color: rgba($primary-color-dark, .2) !important;
        justify-content: center;
        align-items: center;
        color: white;
        p {
          margin: 0;
          font-weight: 600;
        }
    }
  
    .timeline-track {
        flex-grow: 1;
        background-color: rgba($primary-color-dark, .1);
        width: 2px;
        
        &.heighlight {
          &:after {
            content: "";
          }
      }
    }

  
    .heighlight {
        background-color: $steps-color !important;
        border: 0;
        p {
          display: none;
        }
        &:after {
          color: white;
          content: "\2713";
        }
    }

    .actual_step {
      background-color: $primary-color-light;
      border: 0;
       p {
        color: white;
        margin: 0;
       }
      filter: drop-shadow(0px 0px 19px rgba(131, 170, 255, 0.5));
    }
  
    .timeline-event:last-of-type {
        .timeline-point-track-container {
            height: 35px !important;
        }
    }
  }

  &_header {
    background-color: white;
    border-radius: 10px;
    padding: 20px 40px;

    &_code {
      display: flex;
      justify-content: center;
    }

    &_expiration {

      img {
        width: 18px;
        height: 18px;
      }
      border-right: 1px dashed $icon-background;
    }
  }

  &_info {
    background-color: white;
    border-radius: 15px;
    padding: 30px 40px;
    margin-top: 20px;

    h3 {
      font-size: 1.125rem;
      color: $primary-color;
      margin-bottom: 20px;
    }

    &_item {
      display: flex;
      justify-content: space-between;

      p {
        margin: 5px 0;
        font-size: 0.875rem;
        color: $primary-color-dark;
        &:nth-child(2) {
          font-weight: 600;
        }
      }

      &:last-child {
        p {
          &:nth-child(1) {
            font-weight: 600;
          }

          &:nth-child(2) {
            font-size: 1.125rem;
            color: $primary-color-light;
          }
        }
      }
    }

    &_line {
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed $icon-background;
      margin: 10px 0;
    }
  }

  &_accounts {
    
    &_list {
      background-color: white;
      margin-top: 20px;
      border-radius: 15px;
      padding: 30px 40px;
      margin: 20px 0;
      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 0.875rem;
          font-weight: 600;
        }

        &:first-child {
          p {
            &:first-child {
              color: $primary-color;
            }
            &:last-child {
              color: $primary-color-light;
            }
          }
        }
      }

      &_line {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed $icon-background;
        margin: 10px 0;
      }
    }
  }

  &_responsive {
    width: 95%;
    margin: 0 auto;
    display: none;
    &_stepper {

      .stepper-header {
        width: 100% !important;
      }

      .steps {
        padding: 0;
      }

      .step-title {
        line-height: 15px;
      }

    }
    margin-top: 5%;
    h2 {
      font-size: 1.5rem !important;
      margin-bottom: 10px;
      margin-top: 30px;
      text-align: center;
    }

    h3, p {
      font-size: 0.875rem;
      margin-bottom: 5px;
    }

    h3 {
      font-weight: 600;
    }
    
    &_buttons {
      margin-top: 50px;
      button {
        width: 100%;
        margin: 20px 0;
        font-size: 0.775rem !important;
        &:nth-child(1) {
          background-color: $primary-color;
          font-weight: 600;
          color: $white-color;
        }
        &:nth-child(2) {
          border: 1px solid $primary-color;
          color: $primary-color;
          background-color: transparent;
          font-weight: 600;
        }
      }
    }

    &_timeline {
      .timeline {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    
        p {
          font-size: 15px;
        }
      }
    
      .timeline-event {
          display: flex;
          position: relative;
          min-height: 100px;
      }
    
      .heighlight {
          background-color: $steps-color;
      }
    
      .timeline-step-title {
          color: $primary-color-dark;
          p {
            font-size: 0.7225rem;
            font-weight: bold;
            margin: 5px 0 0 0 !important;
          }
    
          .title-grey {
            color: rgba($primary-color-dark, .2);
          }
      }
    
      .timeline-event:last-child {
          min-height: 0;
      }
    
      .timeline-content {
          flex: 1;
          padding: 0 1rem;
      }
    
      .timeline-point-track-container {
          flex: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
      }
    
      .timeline-point {
          display: flex;
          align-self: baseline;
          border-radius: 50%;
          width: 2.3rem;
          height: 2.3rem;
          background-color: white;
          color: rgba($primary-color-dark, .2) !important;
          justify-content: center;
          align-items: center;
          color: white;
          p {
            margin: 0;
            font-weight: 600;
          }
      }
    
      .timeline-track {
          flex-grow: 1;
          background-color: rgba($primary-color-dark, .1);
          width: 2px;
          
          &.heighlight {
            &:after {
              content: "";
            }
        }
      }

    
      .heighlight {
          background-color: $steps-color !important;
          border: 0;
          p {
            display: none;
          }
          &:after {
            color: white;
            content: "\2713";
          }
      }

      .actual_step {
        background-color: $primary-color-light;
        border: 0;
        p {
          color: white;
          margin: 0;
        }
        filter: drop-shadow(0px 0px 19px rgba(131, 170, 255, 0.5));
      }
    
      .timeline-event:last-of-type {
          .timeline-point-track-container {
              height: 35px !important;
          }
      }
    }

    &_header {
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      margin-top: 30px;

      &_code {
        display: flex;
        justify-content: space-between;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &_line {
        width: 90% !important;
        height: 1px;
        margin: 0 auto;
        border-bottom: 1px dashed $icon-background;
      }

      &_expiration {

        h3 {
          text-align: center;
          margin: 0;
        }
        img {
          width: 18px;
          height: 18px;
        }
        
      }
    }

    &_info {
      background-color: white;
      border-radius: 15px;
      padding: 30px 20px;
      margin-top: 20px;

      h3 {
        font-size: 1.125rem;
        color: $primary-color;
        margin-bottom: 20px;
      }

      &_item {
        display: flex;
        justify-content: space-between;

        p {
          margin: 5px 0;
          font-size: 0.875rem;
          color: $primary-color-dark;
          &:nth-child(2) {
            font-weight: 600;
          }
        }

        &:last-child {
          p {
            &:nth-child(1) {
              font-weight: 600;
            }

            &:nth-child(2) {
              font-size: 1.125rem;
              color: $primary-color-light;
            }
          }
        }
      }

      &_line {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed $icon-background;
        margin: 10px 0;
      }
    }

    &_accounts {
      
      &_list {
        background-color: white;
        margin-top: 20px;
        border-radius: 15px;
        padding: 30px 20px;
        margin: 20px 0;
        &_item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 0.875rem;
            font-weight: 600;
          }

          &:first-child {
            p {
              &:first-child {
                color: $primary-color;
              }
              &:last-child {
                color: $primary-color-light;
              }
            }
          }
        }

        &_line {
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed $icon-background;
          margin: 10px 0;
        }
      }
    }
  }
  @include responsive_tablet {
    display: none;

    &_responsive {
      display: block;
    }
  }
}



.my_operations {
  margin: 50px 0;
  h3 {
    font-size: 1.5rem;
    color: $primary-color-dark;
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
    .input {
      width: 100%;
      margin: 0 20px;
      select {
        width: 100%;
        padding: 12px 10px;
        font-size: 0.875rem;
      }

    }
    button {
      width: 100%;
      padding: 12px 0;
      font-size: 0.875rem;
      p {
        margin: 0;
      }

      img {
        margin: 0 10px;
      }
    }
  }

  &_table {
    margin: 40px 0;
    border: 0 !important;
    padding: 0 40px;

    .table {
      color: $primary-color-dark;

      th {
        font-size: 0.625rem;
        font-weight: 700;
      }
      tbody {
        tr {
          &:last-child {
            border: 0px solid transparent !important;
          }
        }
      }
      td {
        padding: 20px 6.5px;
        font-size: 0.875rem;
      }
      &_type {
        font-weight: 600;
        color: $primary-color;
      }
      &_value {
        font-weight: 600;
      }
    }
  }

  .card.my_operations_pagination {
    background-color: transparent;
    border: 0;
    display: flex !important;
    justify-content: center !important;
  }

  &_empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;

    p {
      margin: 20px 0;
      font-weight: 600;
      color: $primary-color-dark;
    }
  }

  @include responsive_tablet {

    &_title {
      text-align: center;
      justify-content: center;
    }

    &_buttons {
      flex-direction: column;

      .input {
        margin: 20px auto;
      }
    }

    &_empty {
      p {
        text-align: center;
      }
    }

    &-title {
      text-align: center;

      p {
        margin: 20px 0;
      }
    }

    &_table {
      overflow: auto;
    }
    .table {
      min-width: 500px;
    }

    nav {
      display: flex;
      justify-content: center;
    }
  }
}