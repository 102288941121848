.accounts {
  &_header {
    display: flex;
    justify-content: space-between;

    button {
      padding: 15px 40px;
    }
  }

  &_list {
    display: flex;
    justify-content: center;
    margin: 50px auto;

    &_item {
      background-color: #fff;
      padding: 30px;
      border-radius: 30px;
      overflow: auto;
      margin: 20px 0;
      &-account {
        display: flex;
        justify-content: space-between;

        &-delete img{
          cursor: pointer;
        }

        &-image {
          width: 45px;
          height: 45px;
        }
      }

      span {
        font-weight: 700;
      }

      p,span {
        font-size: 0.85rem;
      }

      &-type {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        &-currency {
          padding: 0px 20px;
          background-color: $primary-color;
          color: #fff;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.625rem;
          font-weight: 700;
          height: 50%;
        }
      }

      &-alias {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        p {
          margin: 0;
          margin-right: 10px;
        }

        img {
          cursor: pointer;
        }


        input {
          border-radius: 5px;
          border: 1px solid $icon-background;
          padding: 5px;
          outline: 0;
        }

        button {
          padding: 5px 10px;
          margin-left: 10px;
        }
      }

      &-line {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed $icon-background;
        margin: 5px 0;
      }

      &-change {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0 0 0;
        
        p {
          margin: 0;
        }
      }

      .pending-approval {
        padding: 10px;
        color: #fff;
        background-color: $secondary-color;
        border-radius: 8px;
        text-align: center;
        width: 200px;
        height: 43px;
        margin: 15px auto 0 auto;
        user-select: none;
      }

      &-edit {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }

  &_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
    text-align: center;
    padding: 90px 0;
    border-radius: 30px;

    img {
      width: 60px;
    }
    p {
      font-weight: 600;
      margin-top: 20px;
    }
  }

  &_register {

    h2,p {
      text-align: center;
    }

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.875rem;
      margin-bottom: 30px;
    }

    &_form {
      width: 55%;
      background-color: #fff;
      padding: 40px;
      margin: 0 auto;

      p {
        font-size: 0.875rem;
      }
      &-warning {
        border: 1px dashed $primary-color-light;
        border-radius: 8px;
        padding: 10px 0;

        p {
          font-size: 0.75rem;
          text-align: left;
          span {
            font-weight: 600;
          }
        }
      }

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
      }
    }

    &_buttons {
      width: 40%;
      margin: 40px auto;
      display: flex;
      justify-content: space-between;
      button {
        width: 48%;
        font-weight: 600;
        padding: 10px 0;
      }
  
      & button:first-child {
        background-color: $primary-color;
        color: #fff;
      }
  
      & button:last-child {
        background-color: transparent;
        color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
  }

  @include responsive_large_tablet {
    &_register {
      &_form {
        width: 70% !important;
      }
    }
  }

  @include responsive_tablet {

    &_container {
      width: 100% !important;
    }

    &_header {
      flex-direction: column;
      text-align: center;

      button {
        margin: 0 auto;
        margin-top: 20px;
      }
    }

    &_register {
      &_form {
        width: 95% !important;
        padding: 30px;
        p {
          font-size: 0.775rem;
        }
      }
      &_buttons {
        width: 95%;
        flex-direction: column;
        button {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

  }
  
}

.switch {
  position: relative;
  width: 43px;
  height: 15px;
  display: flex;
  justify-content: flex-end;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $icon-background;
  -webkit-transition: .4s;
  transition: .4s;
  width: 40px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: -5px;
  bottom: -5px;
  background-color: white;
  box-shadow: 0px 0px 5px #DFE5F9;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $icon-background;
}

input:checked ~ .slider:before {
  background-color: $primary-color-light;
}


input:focus + .slider {
  box-shadow: 0 0 1px $primary-color-light;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/*------MODALS------*/

.account_modal {
  display: flex;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 1.35rem;
    font-weight: 600;
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    button {
      width: 48%;
      font-weight: 600;

      &:last-child {
        background-color: transparent;
        color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
  }
}