.profile {
  width: 45%;
  margin: 0 auto;
  &_title {
    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 5px;
      font-size: 1.75rem;
    }
    span {
      font-weight: 700;
      font-size: 1.75rem;
    }

    p {
      color: $primary-color;
      font-weight: 600;
      letter-spacing: 1pt;
      font-size: 0.875rem;
    }
  }

  &_line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $icon-background;
    margin: 30px 0;
  }

  &_data {
    width: 100%;
    padding: 20px 0;
    border-radius: 10px;
    &_space {
      padding: 20px 25px;
      margin: 0 !important;
      background-color: #fff;

      .profile-check {
        font-size: 0.875rem;
      }

      &.border-r {
        border-radius: 20px 20px 0 0;
      }
      &.border-rb {
        border-radius: 0 0 20px 20px;
      }

      label {
        font-weight: 700;
        font-size: 0.625rem;
        color: $primary-color;
      }
      p {
        font-size: 1rem;
      }

      &-title {
        p {
          color: $primary-color-light;
          font-weight: 700;
          font-size: 0.85rem;
          margin: 0;
        }
      }

      &-warning {
        display: flex;
        justify-content: flex-start;
        border: 1px dashed $primary-color;
        border-radius: 8px;
        padding: 10px 40px;
        margin: 15px 0;

        &-content {
          display: flex;
          align-items: flex-start;

          p {
            margin-left: 15px;
            margin-bottom: 0;
            font-weight: 600;
          }
          
          p, ul li {
            font-size: 0.75rem;
            color: $primary-color-dark;
          }

          ul {
            margin: 0;
          }
        }
      }
    }

    &_line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $icon-background;
    }

  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    button {
      width: 48%;
      font-weight: 600;

      &:first-child {
        color: $primary-color;
        border: 1px solid $primary-color;
        background-color: transparent;
      }

      &:last-child {
        background-color: $primary-color-light;
        color: #fff;
      }
    }
  }

  &_buttons-edit-profile {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    button {
      width: 48%;
      font-weight: 600;

      &:last-child {
        color: $primary-color;
        border: 1px solid $primary-color;
        background-color: transparent;
      }

      &:first-child {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  &_user_title {
    font-size: 1.5rem;
    margin: 40px;
    text-align: center;
  }

  &_users {
    width: 60%;
  }

  &_user {
    width: 117px;
    height: 117px;
    border-radius: 50%;
  }

  &_list {
    p, span {
      font-weight: 600;
    }

    span {
      font-size: 0.665rem;
    }
  }

  &_add {
    position: relative;
    padding: 10px;
    cursor: pointer;
    &_circle {
      width: 100%;
      height: 100%;
      border: 1px solid $primary-color-light;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img { 
        border-radius: 50%;
      }
    }

    .profile_edit_user {
      position: absolute;
      bottom: 5px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      padding: 7px;
    }
  }

  &_button {
    display: flex;
    justify-content: center;

    button {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
      padding: 10px 40px;
      font-weight: 600;
      margin-top: 50px;
      font-size: 0.875rem;
    }
  }

  @include responsive_large_tablet {
    width: 70%;
  }

  @include responsive_tablet {
    width: 95%;

    &_buttons {
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &_users {
      width: 100%;

      h4 {
        width: 80%;
        margin: 30px auto;
      }
    }

    &_button {
      margin-top: 0;
      margin-bottom: 50px;
    }

    &_title {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &_buttons-edit-profile {
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}