@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
p {
  font-size: 16px;
}

.container {
  width: 85% !important;
  margin: 0 auto;
}

.shadow {
  box-shadow:0px 0px 19px rgba(131, 170, 255, 1);
}

.bradius {
  border-radius: 20px !important;
}

button, .button-primary, .button-primary-light {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  border: 0;
  outline: 0;
  border-radius: 8px !important;
  background-color: $secondary-color;
  color: $white-color;
  font-size: 14px !important;

}

.button-primary {
  background-color: $primary-color;
}

.button-primary-light {
  background-color: $primary-color-light;
}

.button-outline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  border: 0;
  outline: 0;
  border-radius: 8px !important;
  background-color: transparent !important;
  color: $primary-color;
  font-size: 14px !important;
  border: 1px solid $primary-color;
}

.disabled-button {
  filter: brightness(1.8);
  cursor:not-allowed;
}
@media screen and (max-width: 1600px) {
  * {
    font-size: 13px;
  }

  p {
    font-size: 13px;
  }
  
  button {
    font-size: 12px !important;
  }
}

/*-----MODALS-----*/

.modal-dialog {
  .card {
    position: relative;
    .close-button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      background-color: $primary-color;
      border-radius: 50%;
      color: $white-color;
      cursor: pointer;
    }
  }
}

.modal-content {
  width: 90% !important;
  margin: 30px auto;
}

/*-------INPUTS------*/

.input, .input-error {
  position: relative;
  
  input, select, .ng-select.ng-select-single .ng-select-container {
    width: 100%;
    border: 1px solid $icon-background;
    border-radius: 10px;
    padding: 16px 21px;
    outline: 0;
    transition: border ease 200ms;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 100%;
    span {
      font-size: 1.5em;
      img {
        margin-right: 10px;
      }
    }
  }

  label {
    position: absolute;
    height: 20px;
    padding: 0 5px;
    top: -10px;
    left: 20px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary-color;
    background-color: white;
  }

  small {
    font-size: .8rem;
    color: red;
  }

  &-error {
    position: relative;
    input, select {
      border: 1px solid red;
      
    }
    label {
      color: red;
    }
  }
}

/*-------NGX PAGINATION-----*/
.ngx-pagination {
  display: flex !important;
  justify-content: center !important;
  li {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    background-color: $icon-background;
    margin: 0 15px;

    span, a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        background-color: transparent;
      }
    }

  }
  .current {
    background-color: $primary-color-light !important;

    span {
      color: #fff !important;
    }
  }

  .ellipsis {
    background-color: transparent !important;
  }

  @include responsive_tablet {

    padding: 0 !important;
    li {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

/*----MODALES-----*/
.modal-content {
  width: 800px !important;
  height: 100%; /* Altura del modal */
}