.layout {
  display: flex;
  
  &__sidebar {
    width: 450px;
    min-width: 379px;
    background-color: $primary-color;
    //padding: 0 77px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position:fixed;
    z-index: 99999;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }

    @media screen and (max-width: 1600px) {
      width: 400px;
    }

    p {
      font-size: 1rem;
    }
    
    &_header {
      padding: 0 77px;
      height: 90px;
    }

    &_list {
      padding: 0 77px;
      color: $white-color;
      &_item {
        margin-bottom: 15px;
        padding: 15px 26px;
        display: flex;
         align-items: center; 
         p {
          margin: 0 17.25px;
         }

        
        &.item-active {
           background-color: #192B95;
        }
        border-radius: 8px;
        cursor: pointer;

        @media screen and (max-width: 1600px) {
          margin-bottom: 0px;
        }
      }
    }

    .button {
      padding: 0 77px;
    }

    &_slider {
      margin-top: 15% !important;
      margin-bottom: 15%;
      @media screen and (max-width: 1600px) {
        margin-top: 10px !important;
        img {
          width: 150px !important;
        }
      }
    }

    &_footer {
      padding: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.164);
      display: flex;
      justify-content: center;
      color: $white-color;
    }

    transition: all ease 500ms;

    @media screen and (max-width: 1500px) {

      &_footer, &_list, &_header{ 
        padding: 0 15%;
      }

      &_footer {
        padding: 20px;
      }
    }

    @media screen and (max-width: 1200px) {
      margin-left: -260%;
    }

    .menu_responsive {  
      display: none;
    }

    &.active {
      margin-left: 0;
      @include responsive_phone {
        width: 23.6875%;
        min-width: 279px;
        position: fixed;
        z-index: 999;
        .menu_responsive {
          display: block;
        }
      }

      @media screen and (max-width: 1200px) {
        .menu_responsive {
          display: block;
        }
      }
    }
  }
  
  &__main {
    width: 100%;
    height: calc(100vh - 90px);
    margin-left: 450px;
    &_header {
      background-color: $white-color;
      height: 90px;
      display: flex;
      align-items: center;

      &_exchange {
        display: flex;
        align-items: center;
        width: 100%;

        &_line {
          width: 1px;
          height: 20px;
          border-right: 1px solid black;
          margin: 0 50px;
        } 

        p, span {
          margin: 0;
          font-weight: 600;
          font-size: 0.875rem;
        }

        span {
          color: $primary-color;
        }
      }

      &_right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &_alert {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: $white-color;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;

          &_num {
            width: 12px;
            height: 12px;
            position: absolute;
            right: 0;
            top: 5px;
            background-color: red;
            border-radius: 50%;
          }
        }

        &_user {
          margin-left: 20px;
          .btn-secondary {
            background: transparent !important;
            border-radius: 100px !important;
            border: 0 !important;
            display: flex;
            align-items: center;
            padding-right: 20px;
          }
          
          .dropdown {
            a::after {
              margin-left: 20px !important;
            }

            .dropdown-menu {
              margin-top: 15px !important;
              border: 0;
              border-radius: 10px;
              min-width: 290px;
              li {
                display: flex;
                align-items: center;
                padding: 0px 30px;
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0;
                }
                
                &:active {
                  color: $primary-color-dark;
                }

                 p {
                  margin: 0 20px;
                  color: $primary-color-dark;
                  font-size: 0.875rem;
                 }

                 &:hover {
                  background: none;
                  cursor: pointer;
                 }

                 .header_icon {
                  width: 40px;
                  height: 40px;
                  background: $icon-background;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                }
              }
            }
          }

          .user_info {
            text-align: left;
            margin-left: 10px;
            p {
              margin: 0;
            }

            span {
              color: $primary-color;
            }
          }
        }
      }

      &_responsive {
        display: none;
        width: 100%;
        margin-top: 0 !important;
        background-color: #fff;
        height: 90px;
        display: flex;
        align-items: center;        

        .container {
          width: 95% !important;
        }

        .hamburguer {
          width: 32px;
          height: 32px;
          background-image: url('../../assets/header/menu.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          transition: all ease 300ms;
          cursor: pointer;
          
          &.opened {
            background-image: url('../../assets/header/close.svg');
          }
        }

        &_right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          &_alert {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: $white-color;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            img {
              width: 70%;
            }
  
            &_num {
              width: 8px;
              height: 8px;
              position: absolute;
              right: 0;
              top: 5px;
              background-color: red;
              border-radius: 50%;
            }
          }
  
          &_user {
            margin-left: 20px;
            .btn-secondary {
              background: transparent !important;
              border-radius: 100px !important;
              border: 0 !important;
              display: flex;
              align-items: center;
              padding-right: 20px;
            }
            
            .dropdown {
              width: 35px;
              height: 35px;

              img {
                width: 35px;
                height: 35px;
                height: 100%;
                margin: 0 auto;
              }

              a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
              }
              a::after {
                margin-left: 20px !important;
                display: none;
              }
  
              .dropdown-menu {
                margin-top: 15px !important;
                border: 0;
                border-radius: 10px;
                margin-left: -100px !important;
                width: 300px !important;
                
                li {
                  display: flex;
                  align-items: center;
                  padding: 0px 30px;
                  margin-bottom: 20px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  
                  &:active {
                    color: $primary-color-dark;
                  }
  
                   p {
                    margin: 0 20px;
                    color: $primary-color-dark;
                   }
  
                   &:hover {
                    background: none;
                    cursor: pointer;
                   }
  
                   .header_icon {
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    background: $icon-background;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                  }
                }
              }
            }
  
            .user_info {
              text-align: left;
              margin-left: 10px;
              p {
                margin: 0;
              }
  
              span {
                color: $primary-color;
              }
            }
          }
        }

        &_exchange {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 10px 50px;
          background-color: rgba($color: $icon-background, $alpha: 0.3);
          
          p {
            margin: 0;
            color: $primary-color;
            font-weight: 600;
            span {
              font-weight: 600;
              color: $primary-color-dark;
            }
          }
          
          &-line {
            width: 1px;
            height: 20px;
            border-right: 1px solid #C7D1F9;
          }
          
          @include responsive_phone {
            justify-content: space-between;
          }
        }
      }
    }
    &_content {
      background-color: $background-color;
      height: calc(100vh - 90px);
      overflow-y:auto;
      overflow-x: hidden;

    }
    @media screen and (max-width: 1600px) {
      margin-left: 400px;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 0%;

    }

  }
  
}
