.operations-modal {
  padding: 50px 30px;
  text-align: center;
  h4 {
    color: $primary-color-dark;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1.35rem;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 20px 0;
    color: $primary-color-dark;
    background: $unselected-background;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: $selected-background;
    }

    p {
      margin: 0;
      text-align: left;

    }

    span {
      font-weight: 600;
      font-size: 1.20rem;
    }

    &-go {
      width: 46px;
      height: 46px;
      border: 1px solid $primary-color-light;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  p {
    font-size: 0.80rem;
  }
}

@include responsive_phone {
  .operations-modal {

    &__item {
      padding: 20px 10px;

      img {
        width: 50px;
        height: 50px;
      }

      &-go {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    
    h4 {
      color: $primary-color-dark;
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 1.15rem;
    }
    p {
      font-size: 0.90rem;
    }
  }
}
