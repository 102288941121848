.login {
  height: 100vh;
  margin: 0;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* esto he agregado */
    width: 100%;
    height: 100vh;

    &-container {
      width: 433px;
      text-align: center;
      /* esto he agregado */
      overflow-y:auto;
      padding-top:15px;

      h2, p {
        color: $primary-color-dark;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1rem;
      }

      form {
        margin: 30px 0;
      }

      &-bottom {
        p{

          font-size: 0.81rem;
        }
        a {
          color: $primary-color-dark;
          font-weight: 600;
          font-size: 0.81rem;
        }
      }

      button, .input {
        width: 90%;
        margin: 0 auto;
      }
      
      button {
        margin: 0 auto 10px;
      }

      .register_person {
        background-color: rgba($color: $icon-background, $alpha: 0.2);
        padding: 20px 0;
        border-radius: 10px;
        color: $primary-color;
        text-align: left;

        &-line {
          width: 1px !important;
          height: 25px;
          border-right: 1px solid #D4E1FD;
          display: flex;
          justify-content: flex-start;
          padding: 0 !important;
        }
      }

      .register_form {
        .input {
          width: 100%;
        }

        &_details {
          text-align: left;

          p, label, a {
            font-size: 0.75rem; 
            color: $primary-color;
          }
        }
      }

      @include responsive_large_tablet {
        width: 95% !important;
        margin: 0 auto;

        p {
          width: 80%;
          margin: 0 auto;
        }

        h2 {
          margin-top: 50px;
        }

        &-bottom {
          margin-bottom: 50px;
        }
      }
    }

    @include responsive_large_tablet {
      width: 100% !important;
    }
  }
  &__background {
    background-image: url("../../assets/login/background.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 50%;
      bottom: 8%;
      left: -13%;
    }

    @include responsive_large_tablet {
      display: none;
    }
  }
}
