.indicators {
  h1 {
    font-size: 1.5rem;
    margin: 30px 0 40px 0;
  }
  &_operated {
    display: grid;
    grid-template-areas: "graph date"
                         "graph operations";
    grid-template-columns: 1fr 250px;
    grid-template-rows: 250px 250px;
    grid-gap: 30px;


    &_graph {
      grid-area: graph;
      background-color: #fff;
      border-radius: 15px;
      padding: 20px 40px;

      h2 {
        font-size: 1.125rem;
        color: $primary-color-dark;
        font-weight: 600;
        margin-top: 10px;
      }
      
      p {
        font-size: 1.25rem;
        color: $primary-color;
        font-weight: 600;
        margin-bottom: 30px;
      }

      #indicator1 {
        height: 390px !important;
      }

      .highcharts-container {
  
        .highcharts-credits {
            display: none;
        }

  
        .highcharts-exporting-group {
          display: none;
        }
        
      }
    }

    &_date {
      grid-area: date;
      background-color: #fff;
      padding: 30px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        color: $primary-color;
        font-weight: 600;
        font-size: 1.125rem;
        margin-bottom: 7px;
        margin-top: 10px;
      }

      span {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    &_operations {
      grid-area: operations;
      border-radius: 15px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 3.11rem;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 0;
      }
      span {
        font-size: 1rem;
        font-weight: 700;
      }

      &_bottom {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .indicator_circle {
          width: 10px;
          height: 10px;
          background-color: $primary-color-light;
          border-radius: 50%;
        }
        p {
          margin: 0;
          font-size: 0.75rem;
          margin-left: 7px;
        }
      }
    }
  }

  &_estimated {
    display: grid;
    grid-template-areas: "graph transfer"
                         "graph transfer";
    grid-template-columns: 1fr 250px;
    grid-template-rows: 250px 250px;
    grid-gap: 30px;


    &_graph {
      grid-area: graph;
      background-color: #fff;
      border-radius: 15px;
      padding: 20px 40px;

      #indicator2 {
        height: 390px !important;
      }

      h2 {
        font-size: 1.125rem;
        color: $primary-color-dark;
        font-weight: 600;
        margin-top: 10px;
      }
      
      p {
        font-size: 1.25rem;
        color: $primary-color;
        font-weight: 600;
        margin-bottom: 30px;
      }

      .highcharts-container {

  
        .highcharts-credits {
            display: none;
        }

  
        .highcharts-exporting-group {
          display: none;
        }
        
      }
    }

    &_transfer {
      grid-area: transfer;
      background-color: #fff;
      padding: 30px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }

      p {
        color: $primary-color-dark;
        font-weight: 700;
        font-size: 1.125rem;
        margin-bottom: 7px;
        margin-top: 10px;
        text-align: center;
      }
      
      span {
        font-size: 0.75rem;
        font-weight: 500;
        text-align: center;
      }

      button {
        width: 100%;
        background-color: $primary-color-light;
        margin: 20px 0;
      }
    }
  }

  @include responsive_large_tablet {

    h1 {
      text-align: center;
    }
    
    &_operated {
      display: grid;
      grid-template-areas: "graph graph"
                          "date date"
                          "operations operations";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 350px 130px 130px;
      grid-gap: 30px;
      &_graph {
        padding: 20px;
        overflow: auto !important;
        overflow-y: hidden !important;
        &::-webkit-scrollbar {
          width: 5px !important;
          height: 8px !important;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $icon-background; 
          border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $icon-background; 
        }

        p {
          margin-bottom: 10px;
        }
      }
      #indicator1 {
        width: 100% !important;
        width: 800px !important;
        height: 240px !important;
      }

      &_date {
        text-align: center;
        img {
          width: 45px;
          height: 45px;
        }

        p {
          margin: 5px 0;
        }

        span {
          line-height: 10px;
        }
      }
    }

    &_estimated {
      display: grid;
      grid-template-areas: "graph"
                          "transfer";
      grid-template-columns: 1fr;
      grid-template-rows: 350px 300px;
      grid-gap: 30px;

      &_graph {
        padding: 20px;
        overflow: auto !important;
        overflow-y: hidden !important;
        &::-webkit-scrollbar {
          width: 5px !important;
          height: 8px !important;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $icon-background; 
          border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $icon-background; 
        }

        p {
          margin-bottom: 10px;
        }
      }

      &_transfer {
        img {
          width: 120px;
          height: 120px;
          margin-top: 0;
        }
      }


      #indicator2 {
        width: 100% !important;
        width: 800px !important;
        height: 240px !important;
      }
    }
    
    &_operated {
      &_operations {
      &_bottom {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
    }
    }
  }

  @include responsive_tablet {
    #indicator1, #indicator2 {
      width: 100% !important;
      width: 500px !important;
      height: 240px !important;
    }
  }
}