$primary-color: #001489;
$primary-color-dark: #000541;
$primary-color-light: #2C54DC;
$icon-background: #D4E1FD;
$very-light-color: rgba(212, 225, 253, 0.3);
$secondary-color: #F7B825;
$white-color: #ffff;
$background-color: #F6F9FF;
$unselected-background: rgba(212, 225, 253, 0.2);
$selected-background: rgba(212, 225, 253, 0.5);
$steps-color: rgba(64, 192, 43, 1);