.home {
  h1, span {
    font-size: 1.75rem;
    font-weight: bold;
    color: $primary-color-dark;
  }
  span {
    font-weight: normal;
  }

  button {
    padding: 12px 53px;
  }

  &_button {
    display: flex;
    justify-content: flex-end;
  }

  &__graph {
    width: 100%;
    height: 563px;
    background: white;

    &-container {
      padding: 40px 50px 0 50px !important;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h1 {
      font-size: 1.3rem;
      color: $primary-color;
      font-weight: 600;
    }

    button {
      outline: none;
      padding: 10px 15px;
      padding: 10px 20px 10px 20px;
      width: auto;
      border: 1px solid $icon-background;
      border-radius: 8px;
      background-color: transparent !important;
      color: black !important;
      box-shadow: none !important;
      text-align: left;
      
      &:hover {
        border: 1px solid $icon-background;
      }
    }

    ul > li {
      padding: 8px 15px;
      cursor: pointer;
    }

    .chart_container {
      width: 93%;
      margin: 0 auto;
      position: relative;
      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -67%); 
        width: 200px;
        height: 200px;
        pointer-events: none;
      }
    }
    .highcharts-container {
      width: 100% !important;
      height: 95%;
      margin: 0 auto;
  
      .highcharts-root {
          width: 100%;
          height: 100%;

      }

      .highcharts-credits {
          display: none;
      }

      .highcharts-background {
          width: 100% !important;
      }

      .highcharts-xaxis-grid {
          & .highcharts-grid-line {
              stroke: rgb(230, 230, 230);
          }
      }

      .highcharts-yaxis-grid {
          & .highcharts-grid-line {
              stroke: $icon-background;
          }
      }

      .highcharts-exporting-group {
        display: none;
      }
      
    }
  }

  &__analytic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &_flex {
      display: flex;
      align-items: center;
      justify-content: center;
      h2 {
        font-size: 2.375rem;
        font-weight: 600;
        color: $primary-color-light;
        margin-left: 20px;
      }
      p {
        font-size: 1.14rem;
        font-weight: 600;
      }
      &.alerts {
        cursor: pointer;
      }
    }

    h2 {
      font-size: 2.125rem;
      font-weight: 600;
      color: $primary-color-light;
    }
    p {
      font-size: 1.14rem;
      font-weight: 600;
    }

  }

  .recent_operations {
    margin-bottom: 50px;
    h2 {
      font-size: 1.5rem;
      color: $primary-color;
      font-weight: 600;
    }

    a {
      font-size: 1rem;
      font-weight: 600;
    }

    .table_container {
      overflow: auto !important;
      overflow-y: hidden !important;
      &::-webkit-scrollbar {
        width: 5px !important;
        height: 8px !important;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $icon-background; 
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $icon-background; 
      }
    }
    
    table {
      border-bottom: 1px solid #D4E1FD;

      th {
        font-weight: 700;
        color: $primary-color-dark;
        font-size: 0.755rem;
      }
      td {
        padding: 20px 0;
        font-size: 0.875rem;
      }

      .table_amount, .table_type {
        font-weight: 700;
      }

      .table_type {
        color: $primary-color;
      }
    }
  }

  @include responsive_tablet {

    h1 {
      text-align: center;
      margin-bottom: 30px;
    }

    &_button {
      display: flex;
      justify-content: center !important;
      margin-bottom: 30px;
    }

    &__analytic, &_analytic_flex {
      padding: 10px !important;
      text-align: center;

      &.total_operated {
        margin: 0 10px 0 0;
      }
      &.total_savings {
        margin: 0 0 0 10px;
      }
      h2 {
        font-size: 1.2rem;
      }
      p {
        font-size: 1.1rem;
      }
    }


    .recent_operations {

      .table_container {
        overflow: auto;
      }
      table {
        overflow: auto;
        width: 1000px;
      }
    }

    &__graph {
      height: 593px;
      &-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px !important;
        h1 {
          text-align: left !important;
          margin-bottom: 20px !important;
        }
        .btn-group {
          width: 100% !important;
  
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

